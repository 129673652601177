// import { maxWidth } from "./layout"
import { useRouter } from "next/router"
import { twMerge } from "tailwind-merge"

import { Box } from "../box"
import { ArrowBack } from "../icons/arrow-back"
import { Text } from "../text"
import { Button } from "./button"

type SectionProps = {
  showBackButton?: boolean
  children?: React.ReactNode
  header?: React.ReactNode | string
  className?: string
  higherBottomMargin?: boolean
  noBottomMargin?: boolean
  size?: "sm" | "md"
}

export function Section({
  children,
  showBackButton,
  header,
  className = "",
  higherBottomMargin = false,
  noBottomMargin = false,
  size = "md",
}: SectionProps) {
  const router = useRouter()

  return (
    <Box
      row
      full
      className={twMerge(
        `relative mb-10 justify-center overflow-hidden ${
          noBottomMargin ? "mb-0" : higherBottomMargin ? "md:mb-[120px]" : "md:mb-15"
        } px-4 md:px-12 lg:px-4 ${className}`,
      )}
    >
      <Box full className={twMerge(`${size === "md" ? "max-w-[1200px]" : "max-w-[784px]"} relative text-white`)}>
        {showBackButton && (
          <div className="absolute left-0 top-0">
            <Button type="transparent" className="self-start" noPadding noVolume onClick={() => router.back()}>
              <ArrowBack />
              <Text color="primary" className="ml-2">
                Back
              </Text>
            </Button>
          </div>
        )}
        {header && (
          <Text as="h2" className="mb-8 text-center">
            {header}
          </Text>
        )}
        {children}
      </Box>
    </Box>
  )
}
