import React, { useEffect, useState } from "react"

import { useTailwindBreakpoints } from "../hooks/use-tailwind-breakpoints"

type BackgroundImageProps = {
  children?: React.ReactNode
  imageSrc?: string
  hideMobile?: boolean
  className?: string
  contain?: boolean
  size?: "bg-contain" | "bg-cover" | "bg-auto"
}

export function BackgroundImage({
  children,
  imageSrc,
  hideMobile = false,
  className = "",
  contain,
  size = "bg-cover",
}: BackgroundImageProps) {
  const { xs, sm, isMobileLandscape } = useTailwindBreakpoints()
  const [bgImageStyle, setBgImageStyle] = useState<{ backgroundImage?: string }>({
    backgroundImage: `url(${imageSrc})`,
  })

  useEffect(() => {
    hideMobile && setBgImageStyle(!(xs || sm || isMobileLandscape) ? { backgroundImage: `url(${imageSrc})` } : {})
  }, [imageSrc, hideMobile, xs, sm, isMobileLandscape])

  return (
    <div style={bgImageStyle} className={`${contain ? "bg-contain" : size} ${className}`}>
      {children}
    </div>
  )
}
