import type { BoxProps } from "../../box"
import { Box } from "../../box"
import styles from "./gradient-box.module.scss"

type GradientBoxProps = BoxProps & {
  opacity?: "heavy" | "slight"
}

export function GradientBox({ opacity = "slight", ...props }: GradientBoxProps) {
  const gradientBoxClass = opacity === "slight" ? "gradient-box-bg" : "gradient-box-bg-heavy"

  const newProps = {
    ...props,
    className: `${styles[gradientBoxClass]} ${props.className} rounded-box`,
  }

  return <Box {...newProps} />
}
