import React from "react"

import type { BoxProps } from "../box"
import { Box } from "../box"
import styles from "./index.module.scss"

export type CornerProps = {
  lt?: boolean
  rt?: boolean
  lb?: boolean
  rb?: boolean
}

type ClippedBoxProps = BoxProps & {
  corners?: CornerProps
  clipK?: string
}

export function ClippedBox(props: ClippedBoxProps) {
  const clipClassArray: string[] = []
  let clipClass = ""

  if (props.corners) {
    const { lt, rt, rb, lb } = props.corners
    lt && clipClassArray.push("lt")
    rt && clipClassArray.push("rt")
    rb && clipClassArray.push("rb")
    lb && clipClassArray.push("lb")

    clipClass = clipClassArray.join("_")
  }
  const style = { "--clipK": props.clipK || "30px" } as React.CSSProperties

  return <Box style={style} {...props} className={[clipClass ? styles[clipClass] : "", props.className].join(" ")} />
}
