import { ClippedBox } from "../clipped-box/clipped-box"
import type { BoxProps } from "../box"
import { Box } from "../box"

export function ClippedCard(props: BoxProps) {
  const { children, className, ...newProps } = props
  const corners = { rt: true, lb: true }
  return (
    <ClippedBox row corners={corners} className="bg-dark-space p-[3px] relative rounded-box">
      <ClippedBox row corners={corners} full className="p-[2px] bg-deep-space rounded-box">
        <ClippedBox corners={corners} className={["bg-dark-space p-6 rounded-box", className].join(" ")} {...newProps}>
          {children}
        </ClippedBox>
      </ClippedBox>
      {/* top left */}
      <Box className="absolute w-[32px] h-[32px] top-[3px] left-[3px] rounded-tl-xl bg-card-border border-t-2 border-l-2 border-primary shadow-card-tl" />
      <Box className="absolute w-[2px] h-[20px] top-[15px] left-[3px] rounded-tl-xl bg-card-border border-t-2 border-l-2 border-primary shadow-card-line" />
      <Box className="absolute w-[20px] h-[2px] top-[3px] left-[15px] rounded-tl-xl bg-card-border border-t-2 border-l-2 border-primary shadow-card-line" />
      <Box className="absolute w-[15px] h-[0px] top-[8px] left-[2px] shadow-card-point -rotate-45" />
      {/* bottom right */}
      <Box className="absolute w-[32px] h-[32px] bottom-[3px] right-[3px] rounded-br-xl bg-card-border border-b-2 border-r-2 border-primary shadow-card-br" />
      <Box className="absolute w-[2px] h-[20px] bottom-[15px] right-[3px] rounded-br-xl bg-card-border border-b-2 border-r-2 border-primary shadow-card-line" />
      <Box className="absolute w-[20px] h-[2px] bottom-[3px] right-[15px] rounded-br-xl bg-card-border border-b-2 border-r-2 border-primary shadow-card-line" />
      <Box className="absolute w-[15px] h-[0px] bottom-[8px] right-[2px] shadow-card-point -rotate-45" />
    </ClippedBox>
  )
}
