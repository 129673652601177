import Image from "next/image"
import React from "react"

import { Box } from "components/shared/box"
import { Section } from "components/shared/landing/section"

import cguImg from "/public/images/sections/partners-section/cgu.png"
import mcSvg from "/public/images/sections/partners-section/mc.svg"
import mercuryoImg from "/public/images/sections/partners-section/mercuryo.png"
import p2pImg from "/public/images/sections/partners-section/p2p.png"
import playnetaImg from "/public/images/sections/partners-section/playneta.png"
import polygonSvg from "/public/images/sections/partners-section/polygon.svg"
import tuzanyeImg from "/public/images/sections/partners-section/tuzanye.png"
import wintermuteSvg from "/public/images/sections/partners-section/wintermute.svg"

import { Text } from "../shared/text"

const partners = [mcSvg, wintermuteSvg, p2pImg, tuzanyeImg, cguImg, playnetaImg, mercuryoImg, polygonSvg]

type PartnersSectionProps = {
  higherBottomMargin?: boolean
}
export function PartnersSection({ higherBottomMargin }: PartnersSectionProps) {
  return (
    <Section className="flex-col text-white justify-center items-center" higherBottomMargin={higherBottomMargin}>
      <Text as="h2" className="text-center">
        Strategic <span className="text-primary">Partners</span>
      </Text>
      <Box row className="gap-4 mt-6 flex-wrap" center>
        {partners.map((logoImg, index) => (
          <Box key={index} className="w-[calc(100%/3)] md:w-[calc(100%/5)] px-2 py-4 h-[80px]" center>
            <Box full className="justify-center">
              <Image src={logoImg} alt="logo" layout="responsive" objectFit="contain" />
            </Box>
          </Box>
        ))}
      </Box>
    </Section>
  )
}
